import PropTypes from 'prop-types';
import ApplicationModel, { CarrierWaveResource } from './ApplicationModel';

const propTypes = {
  ...ApplicationModel.propTypes,
  name: PropTypes.string,
  logo: PropTypes.exact(CarrierWaveResource.propTypes),
  description: PropTypes.string,
  currency: PropTypes.string,
  adjustActive: PropTypes.bool,
  adjustAppName: PropTypes.string,
  adebisActive: PropTypes.bool,
  adebisLogArgument: PropTypes.string,
  startPage: PropTypes.string,
  deletedAt: PropTypes.string,
};

class Project extends ApplicationModel {
  static propTypes = propTypes;
}

export default Project;
